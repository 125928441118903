import React, { createContext, FC, useContext } from 'react';
import { ShopInfo } from '~/types/ShopInfo';
import useUserAgent from '~/hooks/useUserAgent';

declare global {
  interface Window {
    cs: { shopInfo: ShopInfo };
  }
}

interface SiteContextType extends Record<string, any> {
  theme: {
    borderRadius: number;
    color1: string;
    color2: string;
    color3: string;
    faviconSrc: string | null;
    fontBody: string;
    fontHeading: string;
    iconSet: string;
    productCardAspectRatio: string;
    productImageCrop: boolean;
    productPageRelatedProducts: boolean;
  };
  isMobileAppConsuming: boolean;
  mobileAppVersion: string;
  mobileAppPlatform: MobileAppOS;
  header: {
    announcementBanner: {
      text: string | null;
      uri: string | null;
    };
    layout: string;
    logo: string;
    menu: {
      id: string;
      links: GenericLinkWithChildren[];
    };
  };
  footer: {
    menu?: {
      id: string;
      links?: GenericLinkWithChildren[];
    };
    policyMenu?: {
      id?: string;
      links?: GenericLink[];
    };
    contactText: string;
    socialUrls: {
      [key: string]: string;
    };
  };
  mailingListPopup: any;
}

export interface GenericLink {
  id: string;
  title: string;
  uri: string;
  is_shop_all?: boolean;
  isWeb?: boolean;
}

export interface GenericLinkWithChildren extends GenericLink {
  links: GenericLinkWithChildren[];
  image?: {
    url: string;
  };
}

const SiteContext = createContext<SiteContextType>({} as SiteContextType);

const initialShopInfo: { shopInfo: ShopInfo } = {
  shopInfo: {
    allowCartRemoval: false,
    allowCartUpdate: false,
    appStoreUrls: { android: '', apple: '' },
    appleAppArgument: null,
    appleId: '',
    contactEmail: '',
    description: '',
    facebookPixelId: '',
    featureFlags: {
      lotwReplaysEnabled: true,
      hybridAppEnabled: false,
    },
    googleAnalyticsUid: '',
    csGoogleAnalyticsUid: '',
    hideAvailableStockCount: false,
    homepageDescription: '',
    homepageTitle: '',
    id: '',
    klarnaDisplayOnProductPage: false,
    name: '',
    payPalDetails: null,
    paymentMethods: [],
    pinterestPixelId: '',
    sezzlePublicKey: '',
    shopLogo: '',
    soldKeyword: '',
    tikTokPixelId: '',
    useResponsiveImages: true,
    mobileAppUserAgents: {
      android: '',
      ios: '',
    },
  },
};

const SiteProvider: FC<{ config: any }> = ({ config, children }) => {
  const shopInfo: { shopInfo: ShopInfo } = window.cs ?? initialShopInfo;

  const userAgent = useUserAgent();
  const consumingAppOS = getViewingAppType(
    userAgent,
    shopInfo.shopInfo.mobileAppUserAgents.android,
    shopInfo.shopInfo.mobileAppUserAgents.ios,
  );

  return (
    <SiteContext.Provider
      value={{
        ...shopInfo,
        theme: config?.theme,
        mailingListPopup: config?.mailingListPopup,
        header: config?.header,
        footer: config?.footer,
        homePage: config?.homePage,
        htmlScripts: config?.htmlScripts,
        globals: shopInfo,
        isMobileAppConsuming:
          consumingAppOS !== MobileAppOS.None &&
          shopInfo.shopInfo?.featureFlags?.hybridAppEnabled,
        mobileAppVersion: getAppVersion(userAgent),
        mobileAppPlatform: consumingAppOS,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

function getViewingAppType(
  userAgent: string,
  iosUserAgent: string,
  androidUserAgent: string,
): MobileAppOS {
  if (userAgent.startsWith(androidUserAgent)) {
    return MobileAppOS.Android;
  }

  if (userAgent.startsWith(iosUserAgent)) {
    return MobileAppOS.IOS;
  }

  return MobileAppOS.None;
}

function getAppVersion(userAgent: string): string {
  return userAgent.split('/')[1] ?? '';
}

export enum MobileAppOS {
  Android,
  IOS,
  None,
}

function useSiteContext() {
  return useContext(SiteContext);
}
export { SiteProvider, useSiteContext };
export type { ShopInfo, SiteContextType };
