import React, { FC, useContext } from 'react';

import Button from '~/components/ui/Button';
import { ContainerGrid } from '~/components/layout/PageStructure';
import UIContext from '~/context/UIContext';
import cn from 'classnames';
import { createMarkup } from '~/utils/helpers';
import { placeholderImage } from '~/utils/placeholders';
import { ResponsiveImage } from '~/components/ui/molecules/ResponsiveImage';
import { ImageBlockMedia } from '~/constants/responsiveImageConstants';
import useShopInfo from '~/hooks/live/useShopInfo';
import { useSiteContext } from '~/context/SiteContext';
import { rewriteAnchorTagsFromRawHtml } from '~/helpers/mobileApp/rewriteAnchorTagsFromRawHtml';

export interface ImageWithTextProps {
  anchorTag: string;
  settings: {
    layoutRatio?: string;
    imagePlacement?: string;
    image?: {
      id: string;
      url: string;
    };
    title?: string;
    description?: string;
    cta?: string;
    uri?: string;
  };
}
const ImageWithText: FC<{ data: ImageWithTextProps }> = ({ data = {} }) => {
  const { settings = {}, anchorTag } = data;
  const {
    layoutRatio = '1:1',
    imagePlacement = 'left',
    image,
    title = 'Your image with text block',
    uri = '#',
    cta = 'Call to action',
  } = settings;
  let { description = 'Write some engaging content!' } = settings;
  const previewImage = placeholderImage('portraitMedium');

  const { useResponsiveImages } = useShopInfo();
  const { isMobileAppConsuming } = useSiteContext();

  if (isMobileAppConsuming) {
    description = rewriteAnchorTagsFromRawHtml(description);
  }
  const { breakpoint } = useContext(UIContext);

  const TextBlock = ({ className }: { className: string }) => (
    <div
      className={cn(
        'col-span-12 self-center flex flex-col items-start justify-center mt-3 md:mt-0',
        className,
      )}
    >
      {title && <h3 className="mb-5 text-h2 lg:text-h2-lg">{title}</h3>}
      {description && (
        <p
          className="mb-7 text-body lg:text-subtitle-lg"
          dangerouslySetInnerHTML={createMarkup(description)}
        />
      )}
      {uri && cta && <Button attrs={{ to: uri }} button={{ text: cta }} />}
    </div>
  );

  const ImageBlock = ({ className }: { className: string }) =>
    image && (
      <div className={cn('col-span-12 self-center', className)}>
        <ResponsiveImage
          url={image.url || previewImage.url}
          altText={title}
          aspectRatio="natural"
          className="w-full overflow-hidden rounded"
          media={
            useResponsiveImages
              ? ImageBlockMedia
              : [{ width: 1200, useAsFallback: true }]
          }
        />
      </div>
    );

  return (
    <div className="py-7" id={anchorTag}>
      <ContainerGrid>
        {(imagePlacement === 'left' || breakpoint.isTabletOrSmaller) && (
          <>
            <ImageBlock
              className={cn({
                'md:col-span-6': layoutRatio === '1:1',
                'md:col-span-7': layoutRatio === '2:1',
                'md:col-span-4 lg:col-start-2': layoutRatio === '2:3',
              })}
            />
            <TextBlock
              className={cn({
                'md:col-span-6 md:col-start-7 lg:col-span-5 lg:col-start-8':
                  layoutRatio === '1:1',
                'md:col-span-4 md:col-start-9': layoutRatio === '2:1',
                'md:col-start-6 md:col-span-7 lg:col-span-5 lg:col-start-7':
                  layoutRatio === '2:3',
              })}
            />
          </>
        )}
        {imagePlacement === 'right' && !breakpoint.isTabletOrSmaller && (
          <>
            <TextBlock
              className={cn({
                'md:col-span-6 lg:col-span-5': layoutRatio === '1:1',
                'md:col-span-4': layoutRatio === '2:1',
                'md:col-span-7 lg:col-start-2 lg:col-span-5':
                  layoutRatio === '2:3',
              })}
            />
            <ImageBlock
              className={cn({
                'md:col-span-6 md:col-start-7': layoutRatio === '1:1',
                'md:col-span-7 md:col-start-6': layoutRatio === '2:1',
                'md:col-span-4 md:col-start-9 lg:col-start-8':
                  layoutRatio === '2:3',
              })}
            />
          </>
        )}
      </ContainerGrid>
    </div>
  );
};

export default ImageWithText;
