import Icons from '~/components/ui/Icons';
import React from 'react';
import cn from 'classnames';

type SelectProps = {
  attrs: {
    id: string;
    label: string;
  };
  className: string;
};
const Select: React.FC<SelectProps> = ({
  attrs = {},
  className = '',
  children,
}) => {
  const { id, label } = attrs;

  return (
    <div className={cn('select-group', className)}>
      {label && (
        <label
          className="w-auto mb-1 mx-2 text-button items-center hidden md:flex text-grey"
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <div className="relative w-auto">
        <select className="select w-full appearance-none" {...attrs}>
          {children}
        </select>

        <Icons
          type="down"
          className="chevron center-vertical w-5 pointer-events-none"
        />
      </div>
    </div>
  );
};

export default Select;
