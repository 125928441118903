import React, { SyntheticEvent } from 'react';
import imagePlaceholder from '../../assets/image-placeholder.png';

interface ImageWithFallbackProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallbackSrc?: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  fallbackSrc,
  ...props
}) => {
  return (
    <img
      {...props}
      src={props.src || fallbackSrc || imagePlaceholder}
      alt={props.alt}
      onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = fallbackSrc || imagePlaceholder;
      }}
    />
  );
};

export { ImageWithFallback };
