const cartFragment = `
  freeShippingUntil
  subtotalCents
  freeGiftTopBannerMessage
  itemCount
  items {
    expiresAt
    quantity
    isHeld
    variant {
      id
      size
      color
      priceCents
      salePriceCents
      images {
        url
      }
      product {
        id
        name
        slug
        splitColor
        splitSize
        isFreeGift
        images {
          url
        }
        sku
      }
    }
  }
  waitlistItems {
    quantity
    status
    variant {
      id
      size
      color
      priceCents
      salePriceCents
      images {
        url
      }
      product {
        id
        name
        slug
        splitColor
        splitSize
        isFreeGift
        images {
          url
        }
      }
    }
  }
`;

const cartGet = `
  query {
    shoppingCart {
      ${cartFragment}
    }
  }
`;

const cartAdd = `
  mutation ($id: ID!, $quantity: Int, $sessionSource: String, $sessionSourceId: String, $experience: String, $experienceId: Int) {
    addToCart(variantId: $id, quantity: $quantity, sessionSource: $sessionSource, sessionSourceId: $sessionSourceId, experience: $experience, experienceId: $experienceId) {
      ${cartFragment}
    }
  }
`;

const cartUpdate = `
  mutation ($id: ID!, $quantity: Int!) {
    updateCartQuantity(variantId: $id, quantity: $quantity) {
      ${cartFragment}
    }
  }
`;

const cartRemove = `
  mutation ($id: ID!, $quantity: Int) {
    removeFromCart(variantId: $id, quantity: $quantity) {
      ${cartFragment}
    }
  }
`;

const waitlistRemove = `
  mutation ($id: ID!, $quantity: Int) {
    removeFromWaitlist(variantId: $id, quantity: $quantity) {
      ${cartFragment}
    }
  }
`;

const addEmailToList = `
  mutation ($email: String!) {
    addEmailToList(email: $email)
  }
`;

const productFragment = `
  id
  type
  slug
  brand
  name
  storeDescription
  sku
  splitColor
  splitSize
  isFreeGift
  images {
    url
  }
  videoSource
  videoUrl
  variants {
    id
    priceCents
    salePriceCents
    color
    size
    quantity
    product {
      id
      name
    }
    images {
      url
    }
  }
`;

export {
  cartGet,
  cartAdd,
  cartRemove,
  waitlistRemove,
  cartUpdate,
  addEmailToList,
  productFragment,
};
