import React, { useContext, useEffect, useState } from 'react';
import { useSiteContext } from '~/context/SiteContext';

import { TrackerHelpers } from '~/helpers/tracker.helpers';
import { AnalyticGroups } from '~/types/analytic-groups.enum';
import { TrackingEvent } from '~/types/tracking-event.enum';

const TrackingContext = React.createContext(undefined as any);

const TrackingProvider: React.FC = ({ children }) => {
  const { globals } = useSiteContext();

  const googleAnalyticsUid = globals?.shopInfo?.googleAnalyticsUid;
  const facebookPixelId = globals?.shopInfo?.facebookPixelId;
  const pinterestPixelId = globals?.shopInfo?.pinterestPixelId;
  const tiktokPixelId = globals?.shopInfo?.tikTokPixelId;
  const csGoogleAnalyticsUid = globals?.shopInfo?.csGoogleAnalyticsUid;
  const shopId = globals?.shopInfo?.id;
  const shopTier = globals?.shopInfo?.shop_tier;
  const [isInitialize, setIsInitialize] = useState(false);

  useEffect(() => {
    TrackerHelpers.commentSold.init();
    TrackerHelpers.facebook.init(facebookPixelId);
    TrackerHelpers.googleAnalytics.init(
      googleAnalyticsUid,
      {
        groups: [AnalyticGroups.Shop, AnalyticGroups.All],
      },
      {},
    );
    TrackerHelpers.googleAnalytics.init(
      csGoogleAnalyticsUid,
      {
        groups: [AnalyticGroups.CommentSold, AnalyticGroups.All],
      },
      {
        currency: 'USD',
        user_properties: {
          shop_id: shopId,
          shop_tier: shopTier,
          platform: 'webstore',
        },
      },
    );
    TrackerHelpers.pinterest.init(pinterestPixelId);
    TrackerHelpers.tiktok.init(tiktokPixelId);
    setIsInitialize(true);
  }, [
    facebookPixelId,
    googleAnalyticsUid,
    pinterestPixelId,
    tiktokPixelId,
    csGoogleAnalyticsUid,
    shopId,
    shopTier,
  ]);

  const trackPageView = (pathname: string, search: string) => {
    const pagePath = pathname + search;
    TrackerHelpers.facebook.trackPageView();
    TrackerHelpers.googleAnalytics.trackPageView(pagePath);
    TrackerHelpers.pinterest.trackPageView();
    TrackerHelpers.tiktok.trackPageView();
  };

  const trackEvent = (name: TrackingEvent, data: any, eventData = {}) => {
    TrackerHelpers.commentSold.trackEvent(name, data);
    TrackerHelpers.facebook.trackEvent(name, data, eventData);
    TrackerHelpers.googleAnalytics.trackEvent(name, data);
    TrackerHelpers.pinterest.trackEvent(name, data);
    TrackerHelpers.tiktok.trackEvent(name, data, eventData);
  };

  return (
    <TrackingContext.Provider
      value={{
        trackPageView,
        trackEvent,
        isInitialize,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

function useTracking() {
  return useContext(TrackingContext);
}

export { TrackingProvider, useTracking };
