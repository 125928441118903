import Link from '~/components/ui/Link';
import React, { FC } from 'react';
import { Container } from '~/components/layout/PageStructure';
import { useSiteContext } from '~/context/SiteContext';
import { rewriteAnchorTagsFromRawHtml } from '~/helpers/mobileApp/rewriteAnchorTagsFromRawHtml';

export interface HeroProps {
  anchorTag: string;
  settings: {
    title: string;
    cta?: string;
    uri?: string;
    subtitle: string;
  };
}
export const Hero: FC<{ data: HeroProps; oldLayout: number }> = ({
  data,
  oldLayout,
}) => {
  const { settings, anchorTag } = data;
  const { title, cta, uri } = settings;
  let { subtitle } = settings;
  const { isMobileAppConsuming } = useSiteContext();

  if (isMobileAppConsuming) {
    subtitle = rewriteAnchorTagsFromRawHtml(subtitle);
  }

  return (
    <section className="py-7" id={anchorTag}>
      <Container className="grid justify-items-center text-center">
        {oldLayout === 1
          ? title && (
              <div className="pb-4">
                <h1 className="relative text-h4 lg:text-h4-lg font-light">
                  {title}
                </h1>
              </div>
            )
          : title && (
              <div className="pb-4 prose">
                <h1>{title}</h1>
              </div>
            )}
        {subtitle && (
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-6 md:col-span-4 md:col-start-2">
              <div
                className="py-2 prose"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            </div>
          </div>
        )}
        {cta && uri && (
          <Link
            className="button relative button--primary rounded button--regular mt-4"
            ignoreRouter={true}
            to={uri}
          >
            {cta}
          </Link>
        )}
      </Container>
    </section>
  );
};
