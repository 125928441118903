import React, { useEffect } from 'react';
import Cart from '~/components/cart/Cart';
import CartStructuredData from '~/components/meta/structured-data/CartStructuredData';
import { Container } from '~/components/layout/PageStructure';
import ExpiryTimer from '~/components/cart/ExpiryTimer';
import PageTitle from '~/components/modules/pageTitle';
import SEO from '~/components/meta/SEO';
import { useCart } from '~/context/CartContext';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { GA4Event } from '~/types/google-analytics-4-event.enum';
import { AnalyticGroups } from '~/types/analytic-groups.enum';
import { getVariantPriceDollars } from '~/models/helpers/inventory';

const CartPage = () => {
  const { cart: currentCart, isLoaded } = useCart();
  const title = 'Your cart';

  useEffect(() => {
    if (!currentCart) {
      return;
    }

    webstoreTracker.track({
      eventName: GA4Event.ViewCart,
      eventDetails: {
        currency: 'USD',
        value: currentCart.subtotalCents / 100,
        items: [
          ...currentCart.items.map(item => {
            return {
              item_id: item?.variant?.product?.sku,
              item_name: item?.variant?.product?.name,
              currency: 'USD',
              price: item?.variant
                ? getVariantPriceDollars(item?.variant)
                : null,
              quantity: item?.quantity,
            };
          }),
        ],
        send_to: AnalyticGroups.All,
      },
    });
  }, [currentCart]);

  return (
    <>
      <SEO title={title} />
      <Container>
        <ExpiryTimer
          className="mt-5 mb-6 text-center"
          expiresAt={currentCart?.freeShippingUntil}
          countdownPrefix="Free shipping if you pay within:"
          expiredText={'Free shipping is expired'}
        />
      </Container>
      <PageTitle title={title} textAlign="left" />
      <div className="mb-7">
        {isLoaded &&
        (currentCart?.items?.length || currentCart?.waitlistItems?.length) ? (
          <Cart cart={currentCart} />
        ) : (
          <Container className="mb-9">
            {!isLoaded ? <p></p> : <p>Your cart is empty</p>}
          </Container>
        )}
      </div>

      <CartStructuredData url={window.location.href} title={title} />
    </>
  );
};

export default CartPage;
