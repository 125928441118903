import { Container, ContainerGrid } from '~/components/layout/PageStructure';
import Link from '~/components/ui/Link';
import ProductGrid from '~/components/product/ProductGrid';
import ProductSlider from '~/components/product/ProductSlider';
import React, { useContext, useEffect, useState } from 'react';
import { ProductListLoader } from '~/components/ui/molecules/skeleton-loaders/ProductListLoader';
import { SliderFeaturedCollectionLoader } from '~/components/ui/molecules/skeleton-loaders/SliderFeaturedCollectionLoader';
import createRestClient from '~/utils/createRestClient';
import { ScrollContext } from '~/context/ScrollContext';
import { ProductListItem } from '~/types/Product';
import useWindowScrollPosition from '~/hooks/useWindowScrollPosition';

const restClient = createRestClient();

type FeaturedCollectionProps = {
  data: {
    settings: {
      layout: string;
      collection: string;
      title: boolean | string;
    };
    anchorTag: string;
  };
};
export const FeaturedCollection: React.FC<FeaturedCollectionProps> = ({
  data,
}) => {
  const { dispatch } = useContext(ScrollContext);
  const { settings, anchorTag } = data;
  const { layout = 'grid', collection = '', title = false } = settings;
  const [products, setProducts] = useState<ProductListItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: 'increment' });
    dispatch({ type: 'isLoaded', payload: true });
    (async () => {
      const response = await restClient.getProducts({
        orderBy: 'FEATURED',
        collectionKey: collection,
      });
      setProducts(response.products.data || []);
      dispatch({ type: 'decrement' });
      setIsLoading(false);
    })();
  }, [collection, dispatch]);

  useWindowScrollPosition(window.location.pathname, products.length > 0);
  return (
    <>
      <div className="py-5 pb-3" id={anchorTag}>
        {title && (
          <Container className="mb-6">
            {collection ? (
              <h2 className="text-h4 lg:text-h4-lg">
                <Link className="underline" to={`/collections/${collection}`}>
                  {title}
                </Link>
              </h2>
            ) : (
              <h2 className="text-h4 lg:text-h4-lg">{title}</h2>
            )}
          </Container>
        )}

        {layout === 'grid' && (
          <ContainerGrid className="sm:grid-cols-6">
            <ProductListLoader totalThumbs={15} ready={!isLoading}>
              <ProductGrid
                products={products}
                productClassName="col-span-2 md:col-span-3 mb-3"
              />

              {collection && (
                <div
                  className="col-span-full text-small lg:text-small-lg underline"
                  key="view-more"
                >
                  <Link to={`/collections/${collection}`}>
                    View full collection
                  </Link>
                </div>
              )}
            </ProductListLoader>
          </ContainerGrid>
        )}

        {layout === 'slider' && (
          <SliderFeaturedCollectionLoader ready={!isLoading}>
            <ProductSlider slug={collection} products={products} />
          </SliderFeaturedCollectionLoader>
        )}
      </div>
    </>
  );
};
