import React, { useEffect } from 'react';
import { Modules } from '~/components/modules';
import SEO from '~/components/meta/SEO';
import { useSiteContext } from '~/context/SiteContext';
import { htmlToText } from '~/utils/helpers';
import { Container } from '~/components/layout/PageStructure';
import { LoadableLOTWConnector } from '~/components/live/LoadableLOTWConnector';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { ContentGroups } from '~/types/GoogleAnalyticsContentGroups';
import { ScrollProvider } from '~/context/ScrollContext';

const HomePage = () => {
  const {
    homePage: modules,
    globals: { shopInfo },
    isMobileAppConsuming,
  } = useSiteContext();
  const { homepageTitle, homepageDescription } = shopInfo;

  useEffect(() => {
    webstoreTracker.tagGroup(ContentGroups.HomePage);
  }, []);

  return (
    <ScrollProvider>
      <>
        <SEO
          title={homepageTitle}
          description={htmlToText(homepageDescription)}
        />

        <Container className={'my-6'}>
          {!isMobileAppConsuming && (
            <LoadableLOTWConnector autoConnectToReplay={false} />
          )}
        </Container>

        {modules.map((module: any) => (
          <Modules data={module} type={module.type} key={module.id} />
        ))}
      </>
    </ScrollProvider>
  );
};
export default HomePage;
