import { useEffect, useState } from 'react';

export default function useUserAgent() {
  const [userAgent, setUserAgent] = useState<string>('');

  useEffect(() => {
    setUserAgent(window.navigator.userAgent);
  }, []);

  return userAgent;
}
