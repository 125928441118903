import React, { useEffect, useState } from 'react';
import { useParams as useUrl } from 'react-router-dom';

import ProductGridWithFilters, {
  HeaderProps,
} from '~/components/productlist/ProductGridWithFilters';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import useParams from '~/hooks/useParams';
import { ContentGroups } from '~/types/GoogleAnalyticsContentGroups';
import useRestClient from '~/hooks/useRestClient';
import { CollectionApiResponse } from '~/types/ApiResponseTypes';

interface RouteParams {
  key?: string;
  id?: string;
  slug?: string;
}

const transformCollectionApiToHeader = (
  response: CollectionApiResponse,
): HeaderProps => {
  return {
    pageTitle: response.data.title,
    pageKey: response.data.title,
    seoDescription: response.data.seoDescription,
    seoTitle: response.data.seoTitle || response.data.title,
    contentHTML: response.data.contentHTML,
    canonicalUrl: response.data.canonicalUrl,
  };
};

const ProductThumbs = () => {
  const { key: collectionKey = '' } = useUrl<RouteParams>();
  const { getParam } = useParams();
  const searchQuery = getParam('q') ?? undefined;
  const [headerProps, setHeaderProps] = useState<HeaderProps>();
  const restClient = useRestClient();

  useEffect(() => {
    const tagGroup = searchQuery
      ? ContentGroups.Search
      : ContentGroups.CollectionPage;
    webstoreTracker.tagGroup(tagGroup);
  }, [searchQuery, collectionKey]);

  useEffect(() => {
    if (collectionKey) {
      restClient.getCollection(collectionKey).then(response => {
        setHeaderProps(transformCollectionApiToHeader(response));
      });
    }
  }, [collectionKey, setHeaderProps, restClient]);

  return (
    <ProductGridWithFilters
      productSearch={{
        searchQuery: searchQuery,
        collectionKey,
      }}
      headerProps={headerProps || ({} as HeaderProps)}
      key={searchQuery ?? collectionKey} // so we can tear down component on page change and it resets the state
    />
  );
};

export default ProductThumbs;
