import React, { useEffect, useState } from 'react';
import { Modules } from '~/components/modules';
import { Container, ContainerGrid } from '~/components/layout/PageStructure';
import { LoadableLOTWConnector } from '~/components/live/LoadableLOTWConnector';
import { PageSkeletonLoader } from '~/components/ui/molecules/skeleton-loaders/PageSkeletonLoader';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { ContentGroups } from '~/types/GoogleAnalyticsContentGroups';
import { useHistory, useParams } from 'react-router-dom';
import useRestClient from '~/hooks/useRestClient';
import SEO from '~/components/meta/SEO';
import PageTitle from '~/components/modules/pageTitle';
import PageStructuredData from '~/components/meta/structured-data/PageStructuredData';
import { scriptGeneration } from '~/utils/scriptGenerator';
import { ScrollProvider } from '~/context/ScrollContext';
import { rewriteAnchorTagsFromRawHtml } from '~/helpers/mobileApp/rewriteAnchorTagsFromRawHtml';
import { useSiteContext } from '~/context/SiteContext';

const DynamicPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const [pageData, setPageData] = useState<any>();
  const [pageTitle, setPageTitle] = useState<any>();
  const [isLegacy, setIsLegacy] = useState<boolean>(false);
  const [legacyContent, setLegacyContent] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [oldLayout] = useState<number>(0);
  const history = useHistory();
  const [pageSEO, setPageSEO] = useState<{
    title: string;
    seo_description: string;
  }>();
  const [isResolved, setIsResolved] = useState<boolean>(false);
  const restClient = useRestClient();
  const { isMobileAppConsuming } = useSiteContext();

  useEffect(() => {
    //To be replaces with a context or reducer.
    setPageTitle('');
    setIsResolved(false);
    setIsLegacy(false);
    setPageData(null);

    const fetchData = async () => {
      setIsResolved(false);
      const res = await restClient.getPageInfo(slug).finally(() => {
        // TODO: We need to add more loading functions later
        setIsResolved(true);
      });
      setPageSEO({
        title: res?.data?.page.seo_title,
        seo_description: res?.data?.page.seo_description,
      });
      // if (res?.data?.page.page_layout) {
      //   setOldLayout(res?.data?.page.page_layout);
      // }
      if (res?.data?.page.config && !res?.data?.page.legacy) {
        const {
          sections: pageSection,
          advancedSettings: { scripts },
        } = res?.data?.page.config;
        scriptGeneration(scripts);
        setPageTitle(res?.data?.page.title);
        setPageData(pageSection);
      } else if (res?.data?.page.legacy === null || res?.data?.page.legacy) {
        const { content, title } = res?.data?.page;
        setPageData([]); //TODO: we need to add a 404 page if required
        setIsLegacy(true);
        setLegacyContent(content);
        setTitle(title);
      } else {
        history.replace('/404');
      }
    };
    void fetchData();
  }, [restClient, history, slug]);

  useEffect(() => {
    webstoreTracker.tagGroup(ContentGroups['Page']);
  }, []);

  const createMarkup = (content: string) => {
    return {
      __html: isMobileAppConsuming
        ? rewriteAnchorTagsFromRawHtml(content)
        : content,
    };
  };

  return (
    <ScrollProvider>
      <>
        {pageSEO && (
          <SEO
            title={pageSEO.title || ''}
            description={pageSEO.seo_description || ''}
            pageTitle={pageTitle}
          />
        )}
        <Container className={'my-2'}>
          <LoadableLOTWConnector autoConnectToReplay={false} />
        </Container>
        <PageSkeletonLoader ready={isResolved} />
        {!isLegacy && pageData && isResolved && (
          <div className={'module-items'}>
            {pageData.map((module: any) => (
              <Modules
                data={module}
                type={module.type}
                key={module.id}
                oldLayout={oldLayout}
              />
            ))}
            {pageData.length === 0 && (
              <div className="m-auto w-1/4 my-10">
                No Sections added for {slug} page
              </div>
            )}
          </div>
        )}
        {isLegacy && legacyContent && (
          <>
            {title && <PageTitle title={title} />}
            <ContainerGrid>
              <div
                className="rte mb-9 col-start-1 col-span-4 md:col-start-3 md:col-span-8 content-page-markup__wrapper"
                dangerouslySetInnerHTML={createMarkup(legacyContent)}
                data-testid="legacy-content"
              />
            </ContainerGrid>
            <PageStructuredData
              url={window.location.href}
              title={title || ''}
            />
          </>
        )}
      </>
    </ScrollProvider>
  );
};
export default DynamicPage;
