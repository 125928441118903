import { Container, Grid } from '~/components/layout/PageStructure';
import Button from '~/components/ui/Button';
import LineItem from '~/components/cart/LineItem';
import Link from '~/components/ui/Link';
import SuggestedItems from '~/components/cart/SuggestedItems';
import React, { useEffect } from 'react';
import cn from 'classnames';
import usePrice from '~/hooks/usePrice';
import webstoreTracker from '~/helpers/trackers/webstoreTrackerWrapper';
import { ContentGroups } from '~/types/GoogleAnalyticsContentGroups';
import { ShoppingCart } from '~/types/ShoppingCart';

const Cart: React.FC<{ cart: ShoppingCart }> = ({ cart }) => {
  const subTotal = usePrice(cart.subtotalCents);

  useEffect(() => {
    webstoreTracker.tagGroup(ContentGroups.Cart);
  }, []);

  return (
    <>
      {cart?.items?.length ? (
        <Container>
          <Grid className="hidden border-b col-span-full md:grid border-grey">
            <div className="col-span-5 mb-2">
              <p>Product</p>
            </div>
            <div className="col-span-2 text-center">
              <p>Quantity</p>
            </div>
            <div className="col-span-5 text-right">
              <p>Total</p>
            </div>
          </Grid>

          {cart?.items.map((lineItem, index) => (
            <LineItem
              key={lineItem.variant?.id}
              lineItem={lineItem}
              className={cn('border-grey border-b', {
                'pb-5': index === 0,
                'py-5': index > 0,
              })}
            />
          ))}

          <div className="mt-6 col-span-full">
            <div className="flex justify-between text-subtitle lg:text-subtitle-lg">
              <p>Subtotal</p>
              <p>{subTotal}</p>
            </div>

            <div className="flex justify-end text-right">
              <div className="flex flex-col w-full">
                <p className="mt-2 text-tiny lg:text-tiny-lg">
                  Shipping & taxes calculated at checkout
                </p>
                <div className="flex flex-col mt-3 sm:flex-row sm:justify-between sm:mt-6">
                  <Button
                    className="text-center order-0 sm:order-1"
                    attrs={{
                      to: '/checkout',
                    }}
                    button={{
                      text: 'Checkout',
                    }}
                  />

                  <Link
                    className="self-center mt-5 underline sm:mt-0 text-small lg:text-small-lg"
                    to="/"
                  >
                    Keep shopping
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        <Container className="mb-9">
          <p>Your cart is empty.</p>
        </Container>
      )}
      ;
      <div>
        {cart?.waitlistItems?.length ? (
          <Container className="mt-9 mb-9">
            <h2 className="relative text-h4 lg:text-h4-lg">Waitlist Items</h2>
            <p className="mt-3">
              You have{' '}
              {cart?.waitlistItems?.reduce((accum, current) => {
                const quantity = Number.isInteger(current.quantity)
                  ? current.quantity
                  : 0;
                return accum + quantity;
              }, 0)}{' '}
              items on your waitlist.{' '}
              <Link to="/checkout" className="underline">
                Click here to be first on the list!
              </Link>
            </p>
          </Container>
        ) : (
          <Container className="mt-9 mb-9">
            <h2 className="relative text-h4 lg:text-h4-lg">Waitlist Items</h2>
            <p className="mt-3">No items on your waitlist.</p>
          </Container>
        )}
      </div>
      <SuggestedItems />
    </>
  );
};

export default Cart;
