import PropTypes from 'prop-types';
import React from 'react';
import { FeaturedCollection } from '~/components/modules/featuredCollection';
import { Hero } from '~/components/modules/hero';
import ImageWithText from '~/components/modules/imageWithText';
import ProductGridContainer from '~/components/modules/productGrid/productGridContainer';
import PromoGrid from '~/components/modules/PromoGrid';
import Slideshow from '~/components/modules/slideshow';
import { LiveReplaysModule } from '~/components/modules/LiveReplaysModule';
import ContentSection from './ContentSection';

export const Modules = ({ data, type, oldLayout }) => {
  switch (type) {
    case 'FeaturedCollection':
      return <FeaturedCollection data={data} />;
    case 'ProductGrid':
      return <ProductGridContainer gridData={data} />;
    case 'ImageWithText':
      return <ImageWithText data={data} />;
    case 'Slideshow':
      return <Slideshow data={data} />;
    case 'PromoGrid':
      return <PromoGrid data={data} />;
    case 'LiveReplays':
      return <LiveReplaysModule />;
    case 'HeadingBlock':
      return <Hero data={data} oldLayout={oldLayout} />;
    case 'ContentSection':
      return <ContentSection data={data} oldLayout={oldLayout} />;
    default:
      return <span></span>;
  }
};

Modules.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  oldLayout: PropTypes.number,
};
