import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import AnimateHeight from 'react-animate-height';
import { Container } from '~/components/layout/PageStructure';
import Icons from '../ui/Icons';
import Link from '~/components/ui/Link';
import UIContext from '~/context/UIContext';
import cn from 'classnames';
import { ResponsiveImage } from '~/components/ui/molecules/ResponsiveImage';
import { GenericLinkWithChildren } from '~/context/SiteContext';

// KeyboardEvent.key codes.
const KEY_TAB = 'Tab';
const KEY_SPACE = ' ';
const KEY_ARROW_DOWN = 'ArrowDown';

// KeyboardEvent.which codes (technically deprecated, but included just in case).
const WHICH_TAB = 9;
const WHICH_SPACE = 32;
const WHICH_ARROW_DOWN = 40;

/**
 * Return true if array has sub menu with links
 * @param {array} linklist
 */
const hasChildMenu = (linklist: GenericLinkWithChildren) => {
  return linklist.links?.length > 0;
};

/**
 * Return a nav item based on breakpoint and nav depth
 * @param {object} link
 * @param {depth} number
 * @param {maxDepth} number
 */
type NavItemProps = {
  link: GenericLinkWithChildren;
  depth?: number;
  maxDepth?: number;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
};
const NavItem: React.FC<NavItemProps> = ({
  link,
  depth = 1,
  maxDepth = 5,
  setMenuOpen,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [open, setOpen] = useState(false);
  const [fading, setFading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { breakpoint } = useContext(UIContext);
  const childLinks = link.links || [];
  const isDropdown = childLinks?.length > 0;
  const isMeganav = childLinks?.some(hasChildMenu);
  let singleColumn: GenericLinkWithChildren[] = [];
  let megaNavColumns: GenericLinkWithChildren[] = [];
  const isShopAll = !!link.is_shop_all;

  if (childLinks) {
    singleColumn = childLinks.filter(
      childLink => childLink.links && !childLink.links.length && !isShopAll,
    );

    megaNavColumns = childLinks.filter(
      childLink => (childLink.links && childLink.links.length) || isShopAll,
    );
  }

  /**
   * Expand/contract the child menu for this NavItem. When contrating, slightly delay
   * updating the expanded state to give the child menu 150ms to fade out.
   *
   * @param {bool} isExpanding true if the menu should expand; false if it should contract.
   */
  const expandChildMenu = (isExpanding: boolean) => {
    if (isExpanding) {
      setExpanded(true);
    } else {
      setTimeout(() => {
        setExpanded(false);
      }, 150);
    }

    setFading(isExpanding);
  };

  /**
   * Manage the expanded state of the child menu for this NavItem based on mouse events.
   */
  const onHover = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    expandChildMenu(e.type === 'mouseenter');
  };

  /**
   * Called on the top-level dropdown links to make the header nav keyboard-navigable.
   * Space will expand/contract the dropdown, and the down arrow will expand it (but
   * not contract it). Once visible, users can continue hitting tab to move through the
   * child items. Hitting shift + tab will close the menu.
   */
  const expandHandler = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (!isDropdown) return;

    // Shift + tab: close the menu.
    if (
      expanded &&
      e.shiftKey &&
      (e.key === KEY_TAB || e.which === WHICH_TAB)
    ) {
      expandChildMenu(false);
      return;
    }

    let isExpanded = null;

    if (e.key === KEY_SPACE || e.which === WHICH_SPACE) {
      // Space will swap the expanded state.
      e.preventDefault();
      isExpanded = !expanded;
    } else if (e.key === KEY_ARROW_DOWN || e.which === WHICH_ARROW_DOWN) {
      // The down arrow will only expand the menu.
      e.preventDefault();
      // noinspection PointlessBooleanExpressionJS
      if (!isExpanded) {
        isExpanded = true;
      }
    }

    if (isExpanded !== null) {
      expandChildMenu(isExpanded);
    }
  };

  /**
   * Part of header nav keyboard-navigability. When the user's tabbing through an
   * expanded menu, this will be called on the final child item, and will contract
   * the menu.
   */
  const tabOutHandler = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    // Bail if shift is held down to allow backwards traversal through child items.
    if (
      !expanded ||
      e.shiftKey ||
      !(e.key === KEY_TAB || e.which === WHICH_TAB)
    ) {
      return;
    }

    expandChildMenu(false);
  };

  if (depth === maxDepth) {
    return null;
  }

  return !breakpoint.isDesktop ? (
    <li
      className={cn('header-nav-item flex flex-wrap', {
        'ml-3': depth === 2,
        'ml-2': depth === 5,
      })}
    >
      <div className="flex w-full justify-between">
        {!isShopAll ? (
          <Link
            to={link.uri}
            className={cn(
              'w-1/2 flex-grow mr-2 text-nav pt-4 pb-3 inline-block',
              {
                uppercase: depth !== 5,
              },
            )}
            onClick={() => setMenuOpen(false)}
          >
            {link.title}
          </Link>
        ) : (
          <span
            className={cn(
              'w-1/2 flex-grow mr-2 text-nav pt-4 pb-3 inline-block',
              {
                uppercase: depth !== 5,
              },
            )}
          >
            {link.title}
          </span>
        )}

        {isDropdown && (
          <button
            className={cn('w-7 h-7 transform focus:ring-grey', {
              'rotate-180': open,
            })}
            onClick={() => setOpen(!open)}
          >
            <Icons type="nav" className="inline w-3" ariaLabel="Open sub nav" />
          </button>
        )}
      </div>

      <AnimateHeight height={open ? 'auto' : 0} className={'w-full'}>
        {isDropdown && (
          <ul className="header-nav-child w-full">
            {childLinks.map(childlink => (
              <NavItem
                key={`${link.id}-child-${childlink.id}`}
                link={childlink}
                depth={depth + 1}
                setMenuOpen={setMenuOpen}
              />
            ))}
          </ul>
        )}
      </AnimateHeight>
    </li>
  ) : (
    <li
      className={cn(
        'header-nav-item mx-3',
        isDropdown && !isMeganav && 'relative',
        fading && 'fading',
        expanded && 'expanded',
      )}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
    >
      {isShopAll ? (
        <span className="text-nav lg:text-nav-lg inline-block my-4 border-transparent hover:border-current uppercase">
          {link.title}
        </span>
      ) : (
        <Link
          to={link.uri}
          className="text-nav lg:text-nav-lg inline-block my-4 border-transparent hover:border-current uppercase"
          onKeyDown={expandHandler}
        >
          {link.title}
        </Link>
      )}

      {isDropdown && (
        <Icons type="nav" className="inline w-2 ml-2" ariaLabel="Sub nav" />
      )}

      {isDropdown && !isMeganav && (
        <ul
          className={cn(
            'header-nav-child header-nav-child--dropdown absolute top-full flex-col px-4 py-3 -ml-4 bg-main-0 border border-grey',
            {
              'pointer-events-none': !expanded,
            },
          )}
        >
          {childLinks.map((childlink, i) => (
            <li
              key={`${link.id}-dropdown-child-${childlink.id}`}
              className="my-1"
            >
              <Link
                to={childlink.uri}
                className="lg:text-nav-lg min-w-nav uppercase"
                onKeyDown={e => {
                  if (i === childLinks.length - 1) {
                    tabOutHandler(e);
                  }
                }}
              >
                {childlink.title}
              </Link>
            </li>
          ))}
        </ul>
      )}
      {isMeganav && (
        <div
          className={cn(
            'header-nav-child lg:absolute lg:w-100 lg:left-0 lg:right-0 lg:bg-main-0 pt-3',
            {
              'pointer-events-none': !expanded,
            },
          )}
        >
          <Container
            className={cn({
              'nav_item__shop-all-menu pb-10': isShopAll,
              'flex justify-start flex-wrap': !isShopAll,
              'justify-start': childLinks.length > 5,
              'justify-center ': childLinks.length < 5,
            })}
          >
            {link.image?.url && (
              // I don't think this is used anywhere, but leaving for now
              <div className="my-1 w-1/5 px-3 mb-6">
                <ResponsiveImage
                  aspectRatio="1:1"
                  url={link.image.url}
                  altText={link.title}
                  media={[
                    {
                      width: 500,
                      useAsFallback: true,
                    },
                  ]}
                />
              </div>
            )}
            {!!singleColumn.length && (
              <ul className="w-1/5 px-3 mb-6">
                {singleColumn.map(childlink => (
                  <li
                    className="my-1"
                    key={`${link.id}-meganav-child-${childlink.id}`}
                  >
                    <Link
                      to={childlink.uri}
                      className="text-nav-lg uppercase w-100"
                    >
                      {childlink.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            {megaNavColumns.map((childlink, i) => (
              <ul
                className={cn('px-3 mb-6', {
                  'w-full': isShopAll,
                  'w-1/5': !isShopAll,
                })}
                key={`${link.id}-meganav-child-${childlink.id}`}
              >
                <li className="my-1">
                  <Link
                    to={childlink.uri}
                    className="text-nav-lg uppercase w-100"
                    onKeyDown={e => {
                      if (
                        i === megaNavColumns.length - 1 &&
                        !childlink.links?.length
                      ) {
                        tabOutHandler(e);
                      }
                    }}
                  >
                    {childlink.title}
                  </Link>
                </li>
                {childlink.links.map((grandchildlink, j) => (
                  <>
                    <li
                      key={`${link.id}-${childlink.id}-grandchild-${grandchildlink.id}`}
                    >
                      <Link
                        to={grandchildlink.uri}
                        className={
                          grandchildlink.links.length
                            ? `text-nav-lg uppercase w-100 pl-2`
                            : `text-tiny`
                        }
                        onKeyDown={e => {
                          if (
                            i === megaNavColumns.length - 1 &&
                            j === childlink.links.length - 1
                          ) {
                            tabOutHandler(e);
                          }
                        }}
                      >
                        {grandchildlink.title}
                      </Link>
                    </li>
                    {grandchildlink.links.map((childLvl4: any, j: number) => (
                      <>
                        <li key={`grandchild-lvl-4-${childLvl4.id}`}>
                          <Link
                            to={childLvl4.uri}
                            // className="text-tiny pl-4"
                            className={
                              childLvl4.links.length
                                ? `text-nav-lg uppercase w-100 pl-4`
                                : `text-tiny pl-4`
                            }
                            onKeyDown={e => {
                              if (
                                i === megaNavColumns.length - 1 &&
                                j === childlink.links.length - 1
                              ) {
                                tabOutHandler(e);
                              }
                            }}
                          >
                            {childLvl4.title}
                          </Link>
                        </li>

                        {childLvl4.links.map((childLvl5: any, j: number) => (
                          <li key={`grandchild-lvl-5-${childLvl5.id}`}>
                            <Link
                              to={childLvl5.uri}
                              // className="text-tiny pl-4"
                              className={
                                childLvl5.links.length
                                  ? `text-nav-lg uppercase w-100 pl-4`
                                  : `text-tiny pl-4`
                              }
                              onKeyDown={e => {
                                if (
                                  i === megaNavColumns.length &&
                                  j === childlink.links.length
                                ) {
                                  tabOutHandler(e);
                                }
                              }}
                            >
                              {childLvl5.title}
                            </Link>
                          </li>
                        ))}
                      </>
                    ))}
                  </>
                ))}
              </ul>
            ))}
          </Container>
        </div>
      )}
    </li>
  );
};

export default NavItem;
