import React from 'react';
import { Container } from '~/components/layout/PageStructure';
import { LiveReplaysContainer } from '~/components/live/live-replays/LiveReplaysContainer';

const LiveReplaysModule: React.FC = () => {
  return (
    <Container>
      <div className={'mt-6'}>
        <LiveReplaysContainer />
      </div>
    </Container>
  );
};

export { LiveReplaysModule };
