import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const setupSentry = () => {
  if (!window.sentryConfig && !process.env.REACT_APP_SENTRY_RELEASE) {
    return;
  }

  Sentry.init({
    dsn: window.sentryConfig?.dsn,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.05,
  });
  Sentry.setTag('app', 'webstore');
};

export default setupSentry;
